// LiveChat.jsx
import { useState, useEffect, useRef } from 'react';
import { useUser } from '../contexts/UserContext';

export default function LiveChat() {
  const { user } = useUser();
  const [connected, setConnected] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const wsRef = useRef(null);

  useEffect(() => {
    // Create WebSocket connection
    console.log('Creating WebSocket connection...');
    
    const getWebSocketUrl = () => {
      const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
      const hostname = window.location.hostname;
      
      // In production, we don't need to specify the port as it will use the same port as the main application
      if (process.env.NODE_ENV === 'production') {
        return `${protocol}://${hostname}/ws`;
      }
      
      // In development, use the port from the current window location
      const port = window.location.port;
      return `${protocol}://${hostname}:${port}/ws`;
    };
    
    // Create the WebSocket connection and store it in the ref
    wsRef.current = new WebSocket(getWebSocketUrl());
    
    // The main issue was here - we were using this.socket instead of wsRef.current
    wsRef.current.onopen = () => {
      console.log('WebSocket Connected');
      setConnected(true);
      
      // Send user identification when connection opens
      if (user) {
        wsRef.current.send(JSON.stringify({
          type: 'identify',
          username: user.username
        }));
      }
    };

    wsRef.current.onmessage = (event) => {
      try {
          const data = JSON.parse(event.data);
          console.log('Received data:', data);
          
          if (data.type === 'user_list') {
              setOnlineUsers(data.users);
          }
      } catch (error) {
          console.error('Error processing message:', error);
      }
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket Disconnected');
      setConnected(false);
      setOnlineUsers([]);
    };

    // Cleanup on unmount
    return () => {
      console.log('Cleaning up WebSocket connection');
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
}, [user]); // Adding user to dependency array is correct since we use it in the effect

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h2 className="h5 mb-0">Online Users</h2>
          <span className={`badge ${connected ? 'bg-success' : 'bg-danger'}`}>
            {connected ? 'Connected' : 'Disconnected'}
          </span>
        </div>
        
        <div className="card-body">
          {onlineUsers.length === 0 ? (
            <p className="text-muted mb-0">No users currently online</p>
          ) : (
            <ul className="list-group">
              {onlineUsers.map((username, index) => (
                <li key={index} className="list-group-item d-flex align-items-center">
                  <span className="text-success me-2">●</span>
                  {username}
                  {username === user?.username && ' (You)'}
                </li>
              ))}
            </ul>
          )}
        </div>
        
        <div className="card-footer text-muted">
          <small>
            Total users online: {onlineUsers.length}
          </small>
        </div>
      </div>
    </div>
  );
}